import React from 'react'
import './Banner.css'
function Banner() {
    return (
        <div>
            {/* banner */}
            <div className="tm-top-a-box tm-full-width">
                <div className="uk-container uk-container-center">
                    <section id="tm-top-a" className="tm-top-a uk-grid uk-grid-collapse"
                        data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div className="uk-width-1-1">
                            <div className="uk-panel">
                                <div className="akslider-module ">
                                    <div className="uk-slidenav-position"
                                        data-uk-slideshow="{height: 'auto', animation: 'swipe', duration: '500', autoplay: false, autoplayInterval: '7000', videoautoplay: true, videomute: true, kenburns: false}">
                                        <ul className="uk-slideshow uk-overlay-active">
                                           
                                            <li aria-hidden="true" className="uk-height-viewport">
                                             <div style={{ backgroundImage: "url(https://ik.imagekit.io/kbhsxqdyhof/Football-Stadium_8dY5-2Z2k.jpg?updatedAt=1633411567863)" }}
                                                    className="uk-cover-background uk-position-cover"></div>
                                              
                                                <div className="uk-position-cover uk-flex-middle">
                                             
                                                    <div className="uk-container uk-container-center uk-position-cover">
                                                       
                                                        <div className="va-promo-text uk-width-6-10 uk-push-4-10">                                     
                                                            <h3 id="title"> Just play.<span>Have fun.</span></h3>
                                                            <div className="promo-sub" id="subtitle">Life is <span>about timing</span> Enjoy
                                                                the game</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li aria-hidden="true" className="uk-height-viewport">
                                                <div style={{ backgroundImage: "url(assests/images/stadium2.jpg)" }}
                                                    className="uk-cover-background uk-position-cover"></div>
                                 
                                                <div className="uk-position-cover uk-flex-middle">
                                                    <div className="uk-container uk-container-center uk-position-cover">
                                                        <div className="va-promo-text uk-width-6-10 uk-push-4-10">
                                                            <h3 id="title">Life is <span>about timing</span></h3>
                                                            <div className="promo-sub" id="subtitle">Just play. <span>Have fun.</span> Enjoy
                                                                the game</div>
                                                            {/* <a href="#" className="read-more">Read More<i
                                                                className="uk-icon-chevron-right"></i></a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <a href="#"
                                            className="uk-slidenav uk-slidenav-contrast uk-slidenav-previous"
                                            data-uk-slideshow-item="previous"></a>
                                        <a href="#"
                                            className="uk-slidenav uk-slidenav-contrast uk-slidenav-next"
                                            data-uk-slideshow-item="next"></a>
                                        <ul className="uk-dotnav uk-dotnav-contrast uk-position-bottom uk-text-center">
                                            <li className="uk-active" data-uk-slideshow-item="0"><a
                                                href="#">0</a>
                                            </li>
                                            <li data-uk-slideshow-item="1"><a href="#">1</a>
                                            </li>
                                            {/* <li data-uk-slideshow-item="2"><a href="#">2</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Banner
