import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [help, setHelp] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handlesubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API}/player/helpline`, help)
      .then((res) => {
        setTimeout(() => window.location.reload(), 2000);
        toast.success("Notified", { autoClose: "1000" });
      });
  };
  const handlechange = (e) => {
    setHelp({
      ...help,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <ToastContainer />

      {/* contact */}
      <div className="tm-bottom-e-box tm-full-width">
        <div className="uk-container uk-container-center">
          <section
            id="tm-bottom-e"
            className="tm-bottom-e uk-grid uk-grid-collapse"
            data-uk-grid-margin=""
          >
            <div className="uk-width-1-1">
              <div className="uk-panel">
                <div className="map-wrap">
                  <div className="contact-form-wrap uk-flex">
                    <div className="uk-container uk-container-center uk-flex-item-1">
                      <div className="uk-grid  uk-grid-collapse uk-flex-item-1 uk-height-1-1 uk-nbfc">
                        <div className="uk-width-5-10 contact-left uk-vertical-align contact-left-wrap">
                          <div className="contact-info-lines uk-vertical-align-middle">
                            <div className="item phone">
                              <span className="icon">
                                <i className="uk-icon-phone"></i>
                              </span>
                              <a
                                href="tel:+91 96262 23232"
                                style={{ color: "black" }}
                              >
                                +91 96262 23232
                              </a>{" "}
                            </div>
                            <div className="item mail">
                              <span className="icon">
                                <i className="uk-icon-envelope"></i>
                              </span>
                              <a
                                href="mailto:sporty5erode@gmail.com"
                                style={{ color: "black" }}
                              >
                                {" "}
                                sporty5erode@gmail.com
                              </a>
                            </div>
                            <div className="item location">
                              <span className="icon">
                                <a
                                  style={{ color: "#ffc722" }}
                                  target="_blank"
                                  href="https://goo.gl/maps/UxFKjY1dTCtmgSkA9"
                                >
                                  <i className="uk-icon-map-marker"></i>
                                </a>
                              </span>
                              Veerappampalayam, Erode, Tamil Nadu 638011
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-medium-5-10 uk-width-small-1-1 contact-right-wrap">
                          <div className="contact-form uk-height-1-1">
                            <div className="uk-position-cover uk-flex uk-flex-column uk-flex-center">
                              <div className="contact-form-title">
                                <h2>Get in touch</h2>
                              </div>

                              <div id="aiContactSafe_form_1">
                                <div
                                  className="aiContactSafe"
                                  id="aiContactSafe_mainbody_1"
                                >
                                  <div className="contentpaneopen">
                                    <div id="aiContactSafeForm">
                                      <form
                                        id="adminForm_1"
                                        name="adminForm_1"
                                        onSubmit={handlesubmit}
                                      >
                                        <div id="displayAiContactSafeForm_1">
                                          <div
                                            className="aiContactSafe"
                                            id="aiContactSafe_contact_form"
                                          >
                                            <div
                                              className="aiContactSafe"
                                              id="aiContactSafe_info"
                                            ></div>

                                            <div
                                              className="aiContactSafe_row"
                                              id="aiContactSafe_row_aics_name"
                                            >
                                              <div className="aiContactSafe_contact_form_field_label_left">
                                                <span
                                                  className="aiContactSafe_label"
                                                  id="aiContactSafe_label_aics_name"
                                                >
                                                  <label for="aics_name">
                                                    Name
                                                  </label>
                                                </span>
                                                &nbsp;
                                                <label className="required_field">
                                                  *
                                                </label>
                                              </div>
                                              <div className="aiContactSafe_contact_form_field_right">
                                                <input
                                                  name="name"
                                                  id="aics_name"
                                                  className="textbox"
                                                  placeholder="Name"
                                                  type="text"
                                                  required
                                                  onChange={handlechange}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="aiContactSafe_row"
                                              id="aiContactSafe_row_aics_email"
                                            >
                                              <div className="aiContactSafe_contact_form_field_label_left">
                                                <span
                                                  className="aiContactSafe_label"
                                                  id="aiContactSafe_label_aics_email"
                                                >
                                                  <label for="aics_email">
                                                    E-mail
                                                  </label>
                                                </span>
                                                &nbsp;
                                                <label className="required_field">
                                                  *
                                                </label>
                                              </div>
                                              <div className="aiContactSafe_contact_form_field_right">
                                                <input
                                                  name="email"
                                                  id="aics_email"
                                                  className="email"
                                                  placeholder="Email"
                                                  required
                                                  onChange={handlechange}
                                                  type="email"
                                                />
                                              </div>
                                            </div>
                                            <div className="aiContactSafe_row">
                                              <div className="aiContactSafe_contact_form_field_label_left">
                                                <span
                                                  className="aiContactSafe_label"
                                                  id="aiContactSafe_label_aics_message"
                                                >
                                                  <label for="aics_message">
                                                    Message
                                                  </label>
                                                </span>
                                                &nbsp;
                                                <label className="required_field">
                                                  *
                                                </label>
                                              </div>

                                              <div className="aiContactSafe_contact_form_field_right mt-4">
                                                <textarea
                                                  name="message"
                                                  cols="40"
                                                  rows="10"
                                                  required
                                                  onChange={handlechange}
                                                  placeholder="Message"
                                                />
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <br />

                                        <div id="aiContactSafeBtns">
                                          <div
                                            id="aiContactSafeButtons_center"
                                            style={{
                                              clear: "both",
                                              display: "block",
                                              textAlign: "center",
                                            }}
                                          >
                                            <table
                                              style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div id="aiContactSafeSend_loading_1">
                                                      &nbsp;
                                                    </div>
                                                  </td>
                                                  <td id="td_aiContactSafeSendButton">
                                                    <input
                                                      id="aiContactSafeSendButton"
                                                      value="Send"
                                                      type="submit"
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <br />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Contact;
