import React from 'react'

function About() {
    return (
        <div>
            {/* breadcrumb */}
            <div class="tm-top-a-box tm-full-width tm-box-bg-1 ">
                <div class="uk-container uk-container-center">
                    <section id="tm-top-a" class="tm-top-a uk-grid uk-grid-collapse" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                                <div class="uk-cover-background uk-position-relative head-wrap" style={{ height: "290px", backgroundImage: "url('assests/images/head-bg.jpg')" }}>
                                    <img class="uk-invisible" src="assests/images/head-bg.jpg" alt="" height="290" width="1920" />
                                    <div class="uk-position-cover uk-flex uk-flex-center head-title">
                                        <h1>About</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* Title Bar */}
            <div class="uk-container uk-container-center alt">
                <ul class="uk-breadcrumb">
                    <li><a href="/">Home</a>
                    </li>
                    <li class="uk-active"><span>About</span>
                    </li>
                </ul>
            </div>

            {/* About Description */}
            <div class="tm-bottom-a-box  ">
                <div class="uk-container uk-container-center">
                    <section id="tm-bottom-a" class="tm-bottom-a uk-grid" data-uk-grid-match="{target:'> div > .uk-panel'}"
                        data-uk-grid-margin="">
                        <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                                <div class="about-team-page-top-wrap">
                                    <div class="uk-grid">
                                        <div class="uk-width-large-5-10 uk-width-small-1-1 left-part">
                                            <div class="top-title">
                                                <h2>About <span>team</span></h2>
                                            </div>
                                            <p class="sub-title">Pellentesque a urna sit amet turpis tristique facilisis.
                                                Pellentesque commodo turpis leo, id pulvinar turpis rhoncus interdum. </p>
                                            <p>Nunc ullamcorper varius magna eu tincidunt. Vestibulum eget nisi mattis,
                                                feugiat nisl ac, tempor lacus. Proin a velit rhoncus, imperdiet orci quis,
                                                tempus orci. Duis tincidunt dui nec orci imperdiet, at scelerisque augue
                                                iaculis. Fusce non arcu tortor. Vivamus sapien metus, porta ut ipsum sit
                                                amet, accumsan volutpat libero.</p>
                                            <p>Aenean pretium iaculis diam et semper. Sed nec risus ac nibh rhoncus
                                                scelerisque. Fusce id mauris massa. Aliquam erat volutpat. Maecenas porta
                                                dignissim erat, at vulputate tortor luctus eu. </p>
                                        </div>
                                        <div class="uk-width-large-5-10 uk-width-small-1-1">
                                            <div class="top-banner uk-cover-background uk-position-relative"
                                                style={{
                                                    height: "420px", backgroundImage: "url('assests/images/about-team-banner.jpg')"
                                                }}>
                                                <img class="uk-invisible" src="assests/images/about-team-banner.jpg" alt="" />
                                                <div class="uk-position-cover uk-flex uk-flex-center uk-flex-middle inner">
                                                    “It’s not whether you get knocked
                                                    <br /> down; it’s whether you get up.”
                                                    <div class="name">Vince Lombardi</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div >
    )
}

export default About
