import React from 'react'
// import mail from 'assests/images/gallery/gmail_verif.svg'

function VerifyEmail(props) {
    return (
        <>
           <div  className="d-flex justify-content-center">
               <img width={"500px"} src={"assests/images/gallery/gmail_verif.svg"} />  </div>
               <div> <a style={{textDecoration:"none"}} target="_blank" href="https://mail.google.com/mail/u/0/#inbox">
                    <h3 className="d-flex justify-content-center" style={{color:"black"}}>Verify Email</h3></a>
                    <h3 onClick={props.login} className="d-flex justify-content-center" style={{color:"black"}}>Login</h3>
                    </div>
                   
    </>)
}

export default VerifyEmail
