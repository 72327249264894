import React, { useState, useEffect } from "react";
import axios from "axios";
import './Bookevent.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const token = localStorage.getItem("tok");
const currentTime = new Date();
const year = currentTime.getFullYear();
const month = currentTime.getMonth() + 1;
const date = currentTime.getDate();

function Bookevent() {
  const [book, setBook] = useState({
    stadium_id: "",
    name: "",
    email: "",
    promo: "",
    phoneno: "",
    address: "",
    date: year + "-" + (Number(month) <10? "0" + month : month) + "-" +(date<10?"0"+date:date),
  });
  const [list, setList] = useState([]);
  const [showmore, setShowMore] = useState([]);
  const [slot, setSlot] = useState([]);
  const [promo, setPromo] = useState([]);
  const [tempSlot,setTempSlot]=useState([]);
  const [slotlist, setSlotlist] = useState([]);
  const [bookedslot, setBookedSlot] = useState([]);
  const [bookprice, setBookprice] = useState([]);
  const [discountprice, setDiscountprice] = useState({
    discount_price: 0,
  });
  const dayName=["sunday","monday",'tuesday','wednesday','thursday','friday','saturday'];
  const [err, setErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [slotselected, setSlotSelected] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/player/stadium`).then((res) => {
      setList(res.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/player/slot`).then((res) => {
   try{
   var dateSlot=res.data.data.filter(data=>data.date===book.date);
   var daySlot=res.data.data.filter(data=>data.day===dayName[(new Date).getDay()])
     setTempSlot(res.data.data);
     if(dateSlot.length>0)
      setSlotlist(dateSlot);
      else
      setSlotlist(daySlot)
   }
   catch{
    setSlotlist([])
   }
      axios
        .get(`${process.env.REACT_APP_API}/player/booked_slots`)
        .then((res) => {
          setBookedSlot(res.data.data);
        });
    });
  }, []);
  const promocode = () => 

  { const slots = slotselected; var filteredslots = slotlist
    .filter((data, index) => slots[index])
    .map((data) => Number(data.price));
    console.log(filteredslots);
  var price = 0;
  if (filteredslots.length > 0)
    price = filteredslots.reduce((from, to) => from + to);
    axios
      .post(`${process.env.REACT_APP_API}/player/promo`, { promo: book.promo })
      .then((res) => {
        if (
          res.data.data === "promo-code expired" ||
          res.data.data === "promo code invalid"
        ) {setDiscountprice(0);
          setBookprice(price);
          setErr(res.data.data);
        } else {setDiscountprice(res.data.data);  setErr("");
        setBookprice(price-Number(res.data.data.discount_price));
      }
        console.log(res.data);
      });
  };
  const bookDateChange = (e) => {
    e.preventDefault();
    var dateSlot=tempSlot.filter(data=>data.date===e.target.value);
    var daySlot=tempSlot.filter(data=>data.day===dayName[(new Date(e.target.value)).getDay()])
    console.log(tempSlot)
    if(dateSlot.length>0)
    setSlotlist(dateSlot);
    else
    setSlotlist(daySlot)
    const slot = bookedslot
      .filter((data) => {
        return (
          data.stadium_id === book.stadium_id &&
          data.date === e.target.value
        );
      })
      .map((data) => data.time);
console.log(slot)
    setSlot(slot);
    setBook({ ...book, [e.target.name]: e.target.value });
  };
  const handleslotchange = (e) => {
    setBook({
      ...book,
      [e.target.name]: e.target.value,
    });
  };

  const [slotshow, setSlotshow] = useState(true);


  const handlebook = (e) => {
    e.preventDefault();
    if(!token && book.phoneno.length  !== 10){
      setPhoneErr("Phone number not valid")}
      else{
    const slots = slotlist
      .filter((data, index) => slotselected[index] === true)
      .map((data) => data.id);
    const bookdata=Object.entries(book).filter(([key,value])=>value!=="").map(([key,value])=>{return {[key]:value}});  
    var filteredbook={};
    bookdata.forEach(value=>{filteredbook={...filteredbook,...value}});
    axios
      .post(`${process.env.REACT_APP_API}/player/book_stadium`, {
       
        time: slots.join(","),     
        price:bookprice, 
        ...filteredbook,
      })
      .then((res) => {
        setTimeout(() => {
        if(token){
          window.location.href="/Mybook"
        }
        else{
          window.location.reload()
        }

        }, 2000);
        toast.success("Booked!", { autoclose: "2000" });
      });}
  };

  const Bookevent = () => {
    return (
      <>
        <div className="row">
          {typeof list === "object" && list.length > 0
            ? list.map((x, index) => (
                <>
                  <div className="col-lg-4">
                    <div class="tm-top-a-box">
                      <div class="uk-container uk-container-center">
                        <section
                          id="tm-top-a"
                          class="tm-top-a uk-grid uk-grid-collapse"
                          data-uk-grid-match="{target:'> div > .uk-panel'}"
                          data-uk-grid-margin=""
                        >
                          <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                              <div
                                class="uk-cover-background uk-position-relative head-match-wrap"
                                style={{ marginBottom: "300px" }}
                              >
                                <img
                                  class="uk-invisible"
                                  src={x.photos}
                                  alt={x.name}
                                  style={{ height: "200px" }}
                                />
                                <div class="uk-position-cover uk-flex-center ">
                                  <div class="clear"></div>
                                  <div class="">
                                    <div class="uk-grid no-marg">
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${x.photos})`,
                                        }}
                                        alt={x.name}
                                        class="uk-width-10-10 va-single-next-match match-view-wrap"
                                      >
                                        <div class="va-main-next-wrap">
                                          <div class="match-list-single">
                                            <div class="match-list-item">
                                              <div class="count">
                                                <div id="countdown4">
                                                  <div class="counter_container"></div>
                                                </div>
                                                <div class="clearfix"></div>
                                              </div>
                                              <div class="clear"></div>
                                              <div class="location">
                                                <h1 className="my-0 py-0">
                                                  {x.name}
                                                </h1>
                                              </div>

                                              <div class="clear"></div>
                                              <div class="date">
                                                {!showmore[index]
                                                  ? x.desc.slice(0, 180) + "..."
                                                  : x.desc}{" "}
                                                <br />
                                                <button
                                                  onClick={() =>
                                                    setShowMore({
                                                      ...showmore,
                                                      [index]: !showmore[index],
                                                    })
                                                  }
                                                  className="btn btn-sm btn-warning mt-3"
                                                >
                                                  {!showmore[index]
                                                    ? "Read more"
                                                    : "Read less"}
                                                </button>
                                              </div>
                                              <div class="clear"></div>
                                              <div class="location">  
                                                <div
                                                  className="va-promo-text bookstyle"

                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <a
                                                    className="btn btn-warning read-more "
                                                    data-toggle="modal"
                                                    onClick={() => {
                                                      setSlotshow(false);
                                                      const slot = Array.isArray(bookedslot)?bookedslot
                                                        .filter(
                                                          (data) =>
                                                            data.stadium_id ===
                                                              x.id &&
                                                            data.date ===book.date                
                                                        )
                                                        .map(
                                                          (data) => data.time
                                                        ):[];   setBook({
                                                          ...book,
                                                          img:x.photos,
                                                          stadium_id: x.id,
                                                          stadium_name:x.name
                                                        });
                                                
                                                      setSlot(slot);
                                                   
                                                    }}
                                                  >
                                                    Book
                                                    <i className="uk-icon-chevron-right"></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </>
              ))
            : false}
        </div>
      </>
    );
  };

  const slotSelectChange = (index) => {
    const slots = slotselected;
    slots[index] = !slots[index];
    var filteredslots = slotlist
      .filter((data, index) => slots[index])
      .map((data) => Number(data.price));
    var price = 0;
    if (filteredslots.length > 0)
      price = filteredslots.reduce((from, to) => from + to);
    if(discountprice.discount_price)
    setBookprice(price - Number(discountprice.discount_price));
  else
  setBookprice(price)
    setSlotSelected([...slotselected]);
  };

  
  // login
  const [page, setPage] = useState(true);

  return (
    <div>
      <ToastContainer />
      {/* breadcrumb */}
      <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
        <div className="uk-container uk-container-center">
          <section
            id="tm-top-a"
            className="tm-top-a uk-grid uk-grid-collapse"
            data-uk-grid-match="{target:'> div > .uk-panel'}"
            data-uk-grid-margin=""
          >
            <div className="uk-width-1-1 uk-row-first">
              <div className="uk-panel">
                <div
                  className="uk-cover-background uk-position-relative head-wrap"
                  style={{
                    height: "290px",
                    backgroundImage: "url('assests/images/head-bg.jpg')"
                    // backgroundImage: "url('assests/images/stadiumm.jpg')",
                  }}
                >
                  <img
                    className="uk-invisible"
                    src="assests/images/head-bg.jpg"
                    alt=""
                    height="290"
                    width="1920"
                  />
                  <div className="uk-position-cover uk-flex uk-flex-center head-title">
                    <h1>Book Stadium</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* Title Bar */}
      <div className="uk-container uk-container-center alt">
        <ul style={{ right: "0px" }} className="uk-breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a className="uk-active" href="/bookevent">
              Book Stadium
            </a>
          </li>
          {!slotshow ? (
            <li className="uk-active">
              <span>Book Slot</span>
            </li>
          ) : (
            false
          )}
        </ul>
      </div>
      {/* <!-- Button trigger modal --> */}
      {/* cards */}
      {slotshow ? (
        <Bookevent />
      ) : (
        <div className="container d-block mb-5 p-5">
          <div className="row">
            <div className="col-lg-3">
              <input
                className="my-5"
                type="date"
                value={book.date}
                onChange={bookDateChange}
                name="date"
              />
            </div>
          </div>
 <div className="row">
            {Array.isArray(slotlist) && slotlist?.length > 0
              ? slotlist.map((x, index) => {
                  return (
                    <div
                      style={
                        !slot.join("").includes(String(x.id))
                          ? slotselected[index]
                            ? {
                                background: "#0F2DC4",
                                color: "white",
                                minWidth: "300px",
                              }
                            : { minWidth: "300px" }
                          : { background: "#399EEB", color: "white",minWidth: "300px" }
                      }
                      className="card mx-3 mb-5 col-lg-3"
                      onClick={(e) => {
                        slot.join("").includes(String(x.id))
                          ? (() => {})()
                            : slotSelectChange(index);
                        }}
                    >
                      <div className="mt-4">
                        <p className="d-flex justify-content-center">{(()=>{const from=Number(x.time.split(":")[0]);return (from > 12 ? (from -12 ) +" PM": from+ " AM")+" - "+ ((from +1) > 12 ? ((from +1)-12 ) +" PM": (from +1 )+ " AM")})()  }</p>      
                        <div className="d-flex justify-content-center mt-0">
                          <i className="uk-icon-rupee mt-1" />
                          {x.price}
                        </div>
                        <br />
                      </div>
                    </div>
                  );
                })
              : false}
          </div>
          <div />
       <div >
          {/* <div className="d-flex justify-content-center pb-3">
       
           <span style={{fontWeight:"bold"}}> Discount Price :</span>  
           {discountprice.discount_price  ?    <strike>{discountprice.discount_price}</strike> :0} 
            </div>
      <div className="d-flex justify-content-center pb-3">
       <span style={{fontWeight:"bold"}}> Price :</span> 
        </div> */}
        </div>
          <button
            disabled={!slotselected.includes(true)}
            className="btn btn-block btn-success"    
            data-toggle="modal"
            data-target={"#exampleModalCenter"}
          >
            Book
          </button>
        </div>
      )}
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={false}
      >
        <div className="modal-dialog modal-dialog-centered"  role="document">
          <div className="modal-content bg-transparent "style={{border:'none'}} >
            <div className="modal-body">
              {/* <div>
                <div className="container mb-3 mt-3">
                  <div className="row w-100 d-flex  justify-content-center ">
                    <div
                      className="form-holder ml-5"
                      style={{ width: "500px" }}
                    >
                      <div className="form-content bg-dark">
                        <div className="form-items">
                          <form>
                           
                           
                            <div className="form-button">
                              <button
                                id="submit"
                                onClick={(e) => handlebook(e)}
                                className="ibtn"
                              >
                                Book Now
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div class="container d-flex justify-content-center">
    <figure class="card card-product-grid card-lg"> <a href="#" class="img-wrap" data-abc="true"> <img src={book.img} /> </a>
        <figcaption class="info-wrap">
            <div class="row">
                <div class="col-md-9 col-xs-9"> <a href="#" class="title" style={{textTransform:"capitalize"}} data-abc="true">{book.stadium_name}</a></div>
      
            </div>
        </figcaption>
              {!token ? <>
     <div class="bottom-wrap-payment">
            <figcaption class="info-wrap">
                <div class="row">
                    <div class="col-md-7 col-xs-7"> <input
                              className="form-control"
                              type="text"
                              value={book.email}
                              style={{ color: "black" }}
                              onChange={handleslotchange}
                              name="email"
                              placeholder="Enter Email"
                              required
                            /></div>
                    {/* <div class="col-md-5 col-xs-5">
                        <div class="rating text-right"> <button onClick={promocode} className="btn btn-warning" style={{color:"white"}} type="submit">
          Apply Promocode
          </button></div>
                    </div> */}
                </div>
            </figcaption>
        </div> <div class="bottom-wrap-payment">
            <figcaption class="info-wrap">
                <div class="row">
                    <div class="col-md-7 col-xs-7">   <input
                              className="form-control"
                              type="text"
                              value={book.name}
                              style={{ color: "black" }}
                              onChange={handleslotchange}
                              name="name"
                              placeholder="Enter Name"
                              required
                            /></div>
                    {/* <div class="col-md-5 col-xs-5">
                        <div class="rating text-right"> <button onClick={promocode} className="btn btn-warning" style={{color:"white"}} type="submit">
          Apply Promocode
          </button></div>
                    </div> */}
                </div>
            </figcaption>
            
        </div><div class="bottom-wrap-payment">
            <figcaption class="info-wrap">
                <div class="row">
                    <div class="col-md-7 col-xs-7">    
                            <input
                              className="form-control"
                              type="number"
                              value={book.phoneno}
                              style={{ color: "black" }}
                              onChange={handleslotchange}
                              name="phoneno"
                              placeholder="Enter Phone Number"
                              required
                            /></div>
                    {/* <div class="col-md-5 col-xs-5">
                        <div class="rating text-right"> <button onClick={promocode} className="btn btn-warning" style={{color:"white"}} type="submit">
          Apply Promocode
          </button></div>
                    </div> */}
                </div>
            </figcaption>
            <div className="ml-4" style={{ color: "red" }}>{phoneErr}</div>
        </div> 
      </>:false }
                           
        <div class="bottom-wrap-payment">
            <figcaption class="info-wrap">
                <div class="row">
                    <div class="col-md-7 col-xs-7">   
           <input
            name="promo"
            type="text"
            key={"promo"}
            placeholder="Enter Promo code"
            value={book.promo}
            onChange={handleslotchange}
            className="col-lg-12"
          /> <div style={{ color: !discountprice.discount_price ?  "red":"green" }}>{!discountprice.discount_price?err:"promo code applied"}</div> </div>
                    <div class="col-md-5 col-xs-5">
                        <div class="rating text-right"> <button onClick={promocode} className="btn btn-warning" style={{color:"white"}} type="submit">
          Apply Promocode 
          </button></div>
                    </div>
                </div>
            </figcaption>
        </div>
        <div class="bottom-wrap-payment">
            <figcaption class="info-wrap">
                <div class="row">
                    <div class="col-md-7 col-xs-7"> <a href="#" class="title" data-abc="true"><span class="rated">Total Price : </span> ₹{ bookprice < 0 ? 0 : bookprice }</a> </div>
                    <div class="col-md-5 col-xs-5">
                        <div class="rating text-right"><span class="rated">Discount Price : </span>{discountprice.discount_price  ?  <strike> ₹ {discountprice.discount_price}</strike> : 0}  </div>
                    </div>
                </div>
            </figcaption>
        </div>
        <div class="bottom-wrap"> <button   id="submit"
                                onClick={(e) => handlebook(e)}
                                 class="btn btn-success float-right" data-abc="true"> Book now </button>
        </div>
    </figure></div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

export default Bookevent;
