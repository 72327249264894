import React,{useState,useEffect} from 'react'
import axios from 'axios'
import VerifyEmail from './VerifyEmail'
function Login() {
const [page,setPage]=useState(true)
const [phoneerr,setPhoneerr]=useState("")
const [err,setErr]=useState({
    login:"",
    register:""
})
const [verifyMail,setVerifyEmail]=useState(false);
const[login,setLogin]=useState({
        email:"",
        password:""
})
const[regist,setregist]=useState({
name:"",
email:"",
phone:"",
favsports:"",
address:"",
password:""
})
const handlelogin = (login,e) =>{
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/player/login`,login).then((res)=> {
       setLogin("")
       setregist("")
       if(res.data.data !== "Email/Password mismatch !",res.data.data !== "please verify your email !"&&res.data.data){
       localStorage.setItem('tok',res.data.data) 
       window.location.href="/"     
    }
       else 
       setErr({
           login:res.data.data
       })   
    })
}
const handleregist = (e) =>{
    e.preventDefault()
    if(regist.phone.length !== 10){
        setPhoneerr("Phone number not valid")
    }
    else{
    axios.post(`${process.env.REACT_APP_API}/player/signup`,regist).then((res)=> {
       setVerifyEmail(true);
    }).catch((err)=> {
       if(typeof err === "string"){
        setErr({
            regist:err
        })   
       } 
    })
    }
    
}   
console.log(process.env.REACT_APP_API);
const handleLoginChange = (e) => {
    setVerifyEmail(false);
    setPage(true);
    setregist({
        name:"",
        email:"",
        phone:"",
        favsports:"",
        address:"",
        password:""
        });
}
const handlechange = (e) => {
    setregist({
        ...regist,[e.target.name]:e.target.value
    })
}
const loginchange =(e)=>{
    setLogin({
        ...login,[e.target.name]:e.target.value
    })
}

    return (
        <div>
        
               {/* breadcrumb */}
               <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
                <div className="uk-container uk-container-center">
                    <section id="tm-top-a" className="tm-top-a uk-grid uk-grid-collapse" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div className="uk-width-1-1 uk-row-first">
                            <div className="uk-panel">
                                <div className="uk-cover-background uk-position-relative head-wrap" style={{ height: "290px", backgroundImage: "url('assests/images/head-bg.jpg')" }}>
                                    <img className="uk-invisible" src="assests/images/head-bg.jpg" alt="" height="290" width="1920" />
                                    <div className="uk-position-cover uk-flex uk-flex-center head-title">
                                        <h1>{verifyMail?"Verify Email":"Login"}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
                {/* Title Bar */}
                <div class="uk-container uk-container-center alt">
                <ul class="uk-breadcrumb">
                    <li><a href="/">Home</a>
                    </li>
                    <li class="uk-active"><span>{verifyMail?"Verify Email":"Login"}</span>
                    </li>
                </ul>
            </div>
           {verifyMail? <VerifyEmail login={handleLoginChange}/>:
          
       <div>
        <div className="container mb-3 mt-3">
            <div className="row w-100 d-flex  justify-content-center " >
            <div className="form-holder ml-5" style={{width:"500px"}}>
                <div className="form-content bg-dark" >
                    <div className="form-items">
                        <div className="page-links">
                            <a onClick={()=>setPage(true)} href="#" className={page ? "active" : false}>Login</a>
                            <a onClick={()=>setPage(false)} href="#" className={page ? false : "active" } >Register</a>
                        </div>
                    {page ?    <form> 
                            <input className="form-control" type="text" value={login.email} style={{color:"black"}}  onChange={ loginchange} name="email" placeholder="E-mail Address" required />
                            <input className="form-control" type="password" value={login.password} style={{color:"black"}} onChange={loginchange}  name="password" placeholder="Password" required />
                            <div style={{color:"red"}}>{err.login}</div>
                            <div className="form-button">
                                <button id="submit"  onClick={(e) =>handlelogin(login,e)} style={{color:"black"}} className="ibtn">Login</button> 
                            </div>
                        </form> :
                        <form >
                            <input className="form-control" type="text" style={{color:"black"}} value={regist.name} onChange={ handlechange} name="name"  placeholder="Username" required />
                            <input className="form-control" type="email" name="email" style={{color:"black"}} value={regist.email} onChange={ handlechange} placeholder="E-mail Address" required />
                            <input className="form-control" type="number" name="phone" style={{color:"black"}} value={regist.phone} maxLength='10' onChange={ handlechange} placeholder="Phone" required />
                          <div style={{color:"red"}}>{phoneerr}</div>
                            <input className="form-control" type="text" name="favsports" style={{color:"black"}} value={regist.favsports} onChange={ handlechange} placeholder="Fav Sports" required />
                            <textarea className="form-control" type="text" name="address" style={{color:"black"}} value={regist.address} onChange={ handlechange} placeholder="Address" required />
                            <input className="form-control" type="password" name="password" style={{color:"black"}} value={regist.password} onChange={ handlechange} placeholder="Password" required />
                            <div style={{color:"red"}}>{err.regist}</div>
                            <div className="form-button">
                                <button id="submit"  onClick={handleregist} style={{color:"black"}} className="ibtn">Register</button> 
                            </div>
                        </form>}
                        {/* <div className="other-links">
                            <span>Or login with</span><a href="#">Facebook</a><a href="#">Google</a><a href="#">Linkedin</a>
                        </div> */}
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
}
        </div>
    )
}

export default Login
