import React from 'react'
import Banner from './Banner'
import Gallery from './Gallery'
import GetinTouch from './GetInTouch'
function Home() {
    return (
        <div>
        
            <Banner />
            <Gallery />
            <GetinTouch />
         
        </div>
    )
}

export default Home
