import axios from 'axios'
import React,{useState,useEffect} from 'react'

function Mybook() {
    const[booklist,setBooklist]=useState([])
    const [showmore, setShowMore] = useState([])
    const [slotlist, setSlotlist] = useState([]);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/player/slot`).then((res) => {
            setSlotlist(res.data.data);

        axios.get(`${process.env.REACT_APP_API}/player/history`).then((res)=> { 
            setBooklist(res.data.data)
        })})
    }, [refresh])
    const handleCancel=(id)=>{
        axios.post(`${process.env.REACT_APP_API}/player/cancel_stadium`,{id:id}).then((res)=>setRefresh(!refresh));
    }
    return (
        <>
         {/* breadcrumb */}
         <div class="tm-top-a-box tm-full-width tm-box-bg-1 ">
                <div class="uk-container uk-container-center">
                    <section id="tm-top-a" class="tm-top-a uk-grid uk-grid-collapse" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                                <div class="uk-cover-background uk-position-relative head-wrap" style={{ height: "290px", backgroundImage: "url('assests/images/head-bg.jpg')" }}>
                                    <img class="uk-invisible" src="assests/images/head-bg.jpg" alt="" height="290" width="1920" />
                                    <div class="uk-position-cover uk-flex uk-flex-center head-title">
                                        <h1>Booked</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
                {/* Title Bar */}
                <div class="uk-container uk-container-center alt">
                <ul class="uk-breadcrumb">
                    <li><a href="/">Home</a>
                    </li>
                    <li class="uk-active"><span>Booked Stadium</span>
                    </li>
                </ul>
            </div>
{/* cards */}
<div className="container"> 
<div className="row">
    {Array.isArray(booklist) && booklist.length > 0 ? booklist.map((book,index)=> (
    <div className="col-lg-3">
<div class="card my-4" style={{width: '18rem',textAlign:"center"}}>  
        <img class="card-img-top" src={book.stadium.photos} alt="Card image cap" />
  <div class="card-body">
    <h3 style={{textTransform:"capitalize"}} class="card-title">{book.stadium.name}</h3>
    {!showmore[index] ? book.stadium.desc.slice(0, 180) + "..." : book.stadium.desc}
    <br /><button  onClick={() => setShowMore({ ...showmore, [index]: !showmore[index] })} className="btn btn-sm btn-success mt-3">{!showmore[index] ? "Read more" : "Read less"}</button>                                                                     
  <br />  <a class="btn ">  Date : {book.date}</a>
  <br />  <a class="btn ">  Time : {(() => {
      return slotlist.filter(data=>book.time.includes(data.id)).map(data=>{  const time=Number(data.time.split(":")[0]);return time > 12 ? (time -12 ) +" PM": time+ " AM" } ).join(" , ");
  })()} </a> 
  <br />  <a class="btn ">   Price : <i class="uk-icon-rupee"></i> {book.price}</a><br />
  <a className="btn"><button className="btn btn-sm btn-danger" onClick={()=>handleCancel(book.id)}>Cancel</button></a>
  </div>
</div>
      </div>
      )): <h1>No Stadium book</h1>   }
      </div>
</div>
        </>   
    )
}

export default Mybook
