import React,{useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import './App.css';
import Home from './Pages/Home';
import Footer from './Pages/Footer';
import Gallery from './Pages/Gallery';
import Header from './Pages/Header';
import Contact from './Pages/Contact';
import About from './Pages/About'
import Login from './Pages/Login';
import Bookevent from './Pages/Bookevent';
import Mybook from './Pages/Mybook';
import UpdateProfile from './Pages/UpdateProfile';
import VerifyEmail from './Pages/VerifyEmail';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsandConditions from './Pages/TermsandConditions';
import axios from 'axios';
const token = localStorage.getItem("tok");

function App() {
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/admin/ip`)
  }, [])
  
  return (
    <div >
      <Header />
         <Router>
    
        
         {token ? <Switch>
         <Route path="/" exact component={Home} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/bookevent" exact component={Bookevent} />
          <Route path="/VerifyEmail" exact component={VerifyEmail} />
          <Route path="/updateProfile" exact component={UpdateProfile} />
          <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
          <Route path="/TermsandConditions" exact component={TermsandConditions} />
          
          <Route path="/mybook" exact component={Mybook} />
          <Redirect to="/" />
         </Switch>:<Switch>
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/bookevent" exact component={Bookevent} />
          <Route path="/VerifyEmail" exact component={VerifyEmail} />
          <Route path="/updateProfile" exact component={UpdateProfile} />
          <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
          <Route path="/TermsandConditions" exact component={TermsandConditions} />
          <Route path="/login" exact component={Login} />
          <Route path="/"  component={Home} />
           </Switch>}
       

        </Router>
        <Footer />
    
    </div>
  );
}
export default App;
  