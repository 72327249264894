import React, { useState } from 'react'
const token =localStorage.getItem('tok')
function Header() {
    const[show, setShow] = useState(false)
    const[close,setClose] = useState(false)
    const handlelogout = () =>{
        window.localStorage.clear()
        window.location.href="/"
     }  
     const showList=()=>{
         setShow(!show)
         setClose(!close)
     }


    return (
        <div>
            {/* Navbar */}
           
            <div className="tm-menu-box">
                <div style={{ height: "70px" }} className="uk-sticky-placeholder">
                    <nav style={{ margin: "0px" }} className="tm-navbar uk-navbar" data-uk-sticky="">
                        <div className="uk-container uk-container-center">
                            <a className="tm-logo uk-float-left" href="#">
                                <img src="assests/images/sporty.svg" alt="logo"  width="100px" title="logo" /> 
                                {/* <span>Sporty<em>5</em></span> */}
                            </a>
                            <a href="#offcanvas" className={close? "uk-icon-close" : "uk-navbar-toggle uk-visible-small"} 
                            style={close? {float: 'right', marginTop:'10px', fontSize:'25px', textDecoration:'none', color:'white', boxSizing:'border-box', display:'flex', height:'45px', alignItems:'center', padding:'0 15px'} : null} onClick={showList}></a>
                            {show?
                                <ul className="uk-navbar-nav mt-5" style={{background:'black', display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <li>
                                    <a href="/" style={{color:'white',fontWeight:"bold"}}>Home</a>
                                </li>                             
                                <li><a href="/bookevent" style={{color:'white',fontWeight:"bold"}}>Book Stadium</a>
                                </li>
                                {token ? <li><a href="/mybook" style={{color:'white',fontWeight:"bold"}}>My Books</a>
                                </li> :false }
                               
                                <li><a
                                    href="/about" style={{color:'white',fontWeight:"bold"}}>About</a>
                                    </li>
                                <li><a href="/contact" style={{color:'white',fontWeight:"bold"}}>Contact</a></li>
                                                               
                                 {token ? <li>
                                    <a href="/updateProfile" style={{color:'white',fontWeight:"bold"}}> Profile</a>
                                    </li>
                                  :<li><a href="/login" style={{color:'white',fontWeight:"bold"}}>Login</a>
                                </li> 
                                }
                                {token ?<li><a  style={{color:'white',fontWeight:"bold"}}onClick={handlelogout} >Logout</a>
                                </li> :false}

                                <li><a  style={{color:'white',fontWeight:"bold"}}  target="_blank" href="https://goo.gl/maps/UxFKjY1dTCtmgSkA9" >Address</a></li>
                                
                            </ul> :
                            <ul className="uk-navbar-nav uk-hidden-small">
                                <li>
                                    <a href="/" style={{color:'white',fontWeight:"bold"}}>Home</a>
                                </li>
                              
                              
                                <li><a href="/bookevent" style={{color:'white',fontWeight:"bold"}}>Book Stadium</a>
                                </li>
                            {token ? <li><a href="/mybook" style={{color:'white',fontWeight:"bold"}}>My Books</a>
                                </li> :false }
                              
                                <li><a
                                    href="/about" style={{color:'white',fontWeight:"bold"}}>About</a>
                                    </li>
                                <li><a href="/contact" style={{color:'white',fontWeight:"bold"}}>Contact</a></li>
                                                               
                                 {token ? <li>
                                    <a href="/updateProfile" style={{color:'white',fontWeight:"bold"}}> Profile</a>
                                </li>
                                  :<li><a href="/login" style={{color:'white',fontWeight:"bold"}}>Login</a>
                                </li> 
                                }
                                {token ?<li><a style={{color:"white",fontWeight:"bold"}} onClick={handlelogout} >Logout</a>
                                </li> :false}
                                
                                <li><a style={{color:"white",fontWeight:"bold"}} target="_blank" href="https://goo.gl/maps/UxFKjY1dTCtmgSkA9">Address</a></li>
                            </ul>
                            }
                        </div>
                    </nav>
                </div>
            </div>
             
    
        </div>
    )
}

export default Header