import React from 'react'

function Gallery() {
    return (
        <div>
                {/* breadcrumb */}
             

            {/* Gallery */}
            <div className="tm-top-g-box tm-full-width">
                <div className="uk-container uk-container-center">
                    <section id="tm-top-g" className="tm-top-g uk-grid" data-uk-grid-match="{target:'> div > .uk-panel'}"
                        data-uk-grid-margin="">
                        <div className="uk-width-1-1">
                            <div className="uk-panel">
                                <div style={{background:"white",display:"flex",justifyContent:"center",fontWeight:"bold"}} className="gallery-title">
                                    <div className="uk-container uk-container-center tt-gallery-top-main">
                                        <div className="uk-grid" data-uk-grid-match="">
                                            <div className="uk-width-3-10 title">Gallery</div>
                            </div>
                            </div>
                                </div>
                                
                                <div className="uk-sticky-placeholder">
                                    <div style={{ margin: '0px' }} className="button-group filter-button-group"
                                        data-uk-sticky="{top:70, boundary: true}">
                                        <div className="uk-container uk-container-center">
                                            {/* <button className="active" data-filter="*">all</button> */}
                                            {/* <button data-filter=".tt_c26e2589e25045ad516b5bc37d18523a">Volleyball</button>
                                            <button data-filter=".tt_6081becaf04f5a1455407d73e09bca13">Hockey</button>
                                            <button data-filter=".tt_c71a18083d9e74b4a5c5d8d9a17d68d0">Basketball</button>
                                            <button data-filter=".tt_449a5f6d01d5f416810d04b4df596b6a">Football</button>
                                            <button data-filter=".tt_ea9d8d12fefde9e2f9c4631a76504ce7">Rugby</button> */}
                                        </div>
                                    </div>
                                </div>

                                <div id="boundary">

                                    <div className="uk-grid uk-grid-collapse grid" data-uk-grid-match="">


                                    <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item article-slider tt_c26e2589e25045ad516b5bc37d18523a ">
                                            <div className="uk-slidenav-position" data-uk-slideshow="{height:300}">
                                                <ul className="uk-slideshow">
                                                    <li className="uk-active" aria-hidden="false">
                                                        <div style={{ backgroundImage: "url(assests/images/1448838000_1662651f7f781a887707a2836033aa63.jpg)" }}
                                                            className="uk-cover-background uk-position-cover"></div>
                                                    
                                                    </li>
                                                    <li aria-hidden="true">
                                                        <div style={{ backgroundImage: "url(assests/images/1448838000_7e2a4fc5579be1e525f7b338af8bcc4e.jpg)" }}
                                                            className="uk-cover-background uk-position-cover"></div>
                                                        <img style={{ width: '100%', height: 'auto', opacity: '0' }}
                                                            className="uk-responsive-height"
                                                            src="assests/images/1448838000_7e2a4fc5579be1e525f7b338af8bcc4e.jpg"
                                                            alt="" />
                                                        <div className="titles">
                                                            <div className="sub-title">
                                                                Donec vel orci sed leo semper viverra </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                               
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_c26e2589e25045ad516b5bc37d18523a ">
                                            <div className="gallery-album">
                                                <a 
                                                    className="img-0">
                                                    <img src="assests/images/03f1869954e6e557b8ac56b508030a3b.jpg" alt="" />
                                                </a>
                                               
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Volleyball </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_6081becaf04f5a1455407d73e09bca13 ">
                                            <div className="gallery-album">
                                                <a 
                                                    className="img-0">
                                                    <img src="assests/images/8cc1994dde069571bfe5edf1e7822185.jpg" alt="" />
                                                </a>
                                               
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Hockey </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_6081becaf04f5a1455407d73e09bca13 ">
                                            <div className="gallery-album">
                                                <a 
                                                    className="img-0">
                                                    <img src="assests/images/62448a01125cebccfa3512491a345da9.jpg" alt="" />
                                                </a>
                                                <a 
                                                  className="img-1">
                                                    <img src="assests/images/14f0c92ade7d754a98d2b6ddd4fe560a.jpg" alt="" />
                                                </a>
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Hockey </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_c71a18083d9e74b4a5c5d8d9a17d68d0 ">
                                            <div className="gallery-album">
                                                <a 
                                               className="img-0">
                                                    <img src="assests/images/c9e61645f3f740197afa7fb17bf3d3ad.jpg" alt="" />
                                                </a>
                                                <a 
                                               className="img-1">
                                                    <img src="assests/images/a46d465cb53412b43c73d9c793083875.jpg" alt="" />
                                                </a>
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Basketball </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_c71a18083d9e74b4a5c5d8d9a17d68d0 ">
                                            <div className="gallery-album">
                                                <a 
                                                   className="img-0">
                                                    <img src="assests/images/d5d9d0e4068673aee603250d1eb43af8.jpg" alt="" />
                                                </a>
                                                <a 
                                                   className="img-1">
                                                    <img src="assests/images/6479eff8244e3eeb793efa29559f45f7.png" alt="" />
                                                </a>
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Basketball </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_449a5f6d01d5f416810d04b4df596b6a tt_c71a18083d9e74b4a5c5d8d9a17d68d0 tt_ea9d8d12fefde9e2f9c4631a76504ce7 ">
                                            <div className="gallery-album">
                                                <a 
                                               className="img-0">
                                                    <img src="assests/images/49633121e88e2125a7069937885d5163.jpg" alt="" />
                                                </a>
                                                <a 
                                               className="img-1">
                                                    <img src="assests/images/4510067e63319389d8587e2cb12d4346.jpg" alt="" />
                                                </a>
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Douglas Payne </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="uk-width-1-1 uk-width-medium-1-2 uk-width-large-1-4 grid-item tt_c26e2589e25045ad516b5bc37d18523a ">
                                            <div className="gallery-album">
                                                <a 
                                                   className="img-0">
                                                    <img src="assests/images/fb04791e435ada34da98c5ca40642149.jpg" alt="" />
                                                </a>
                                                <a 
                                                   className="img-1">
                                                    <img src="assests/images/20e17d247276908ce9c879c785afad72.jpg" alt="" />
                                                </a>
                                                <div className="titles">
                                                    <div className="title">Douglas Payne </div>
                                                    <div className="sub-title">Douglas Payne </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </div>
    )
}

export default Gallery
