import React from 'react'

function Contact() {
    return (
        <div>
            <div class="tm-top-a-box tm-full-width tm-box-bg-1 ">
                <div class="uk-container uk-container-center">
                    <section id="tm-top-a" class="tm-top-a uk-grid uk-grid-collapse" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                                <div class="uk-cover-background uk-position-relative head-wrap" style={{ height: "290px", backgroundImage: "url('assests/images/head-bg.jpg')" }}>
                                    <img class="uk-invisible" src="assests/images/head-bg.jpg" alt="" height="290" width="1920" />
                                    <div class="uk-position-cover uk-flex uk-flex-center head-title">
                                        <h1>Contact</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div class="uk-container uk-container-center alt">
                <ul class="uk-breadcrumb">
                    <li><a href="/">Home</a>
                    </li>
                    <li class="uk-active"><span>Contact</span>
                    </li>
                </ul>
            </div>

            {/* Contact Description */}

            <div class="tm-bottom-a-box  ">
                <div class="uk-container uk-container-center">
                    <section id="tm-bottom-a" class="tm-bottom-a uk-grid" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div class="uk-width-1-1 uk-row-first">
                            <div class="uk-panel">
                                <div class="contact-page">
                                    <div class="uk-grid">
                                        <div class="uk-width-1-1">
                                            <div class="contact-title">
                                                <h2>Nulla vehicula sem id nisl fringilla porta</h2>
                                            </div>
                                            <div class="contact-text">Aenean aliquam, dolor eu lacinia pellentesque, dui arcu condimentum nisl, quis sollicitudin mi lorem quis leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis sapien a ante rutrum pulvinar quis ac tellus. Proin facilisis dui at mollis tincidunt. Sed dignissim orci non arcu luctus pretium. Donec at ex aliquet, porttitor lacus eget, ullamcorper quam. Integer pellentesque egestas arcu, nec molestie leo sollicitudin et</div>
                                        </div>
                                        <div class="uk-width-1-1">
                                            <div class="contact-socials-wrap">
                                                <ul class="contact-socials">
                                                    <li><a href="https://www.facebook.com/Sporty5-104099878678846" target="_blank"><i class="uk-icon-facebook"></i></a></li>
                                                    <li><a href="mailto:sporty5erode@gmail.com" target="_blank"><i class="uk-icon-google"></i></a></li>
                                                    <li><a href="https://www.instagram.com/_sporty5/" target="_blank"><i class="uk-icon-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uk-grid d-flex justify-content-center" data-uk-grid-match="{target:'.contact-enquiries'}">
                                        <div class="uk-width-medium-1-3 uk-panel" >
                                            <div  class="contact-enquiries" style={{ minHeight: '140px' }}>
                                                <div class="title" ><i class="uk-icon-phone"></i> MOBILE NUMBER</div>
                                                <a href="tel:9626223232"  style={{color:"black",display:"flex"}} class="phone">+91 96262 23232</a>
                                            </div>
                                        </div>
                                      
                                        <div class="uk-width-medium-1-3 uk-panel" style={{ minHeight: '140px' }}>
                                            <div  class="contact-enquiries">
                                                <div class="title">    <i class="uk-icon-envelope"></i>GMAIL</div>
                                                <div class="mail">
                                                    <a href="mailto:sporty5erode@gmail.com" style={{color:"black"}}>
                                                    sporty5erode@gmail.com
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uk-width-medium-1-3 uk-panel" style={{ minHeight: '140px' }}>
                                            <div  class="contact-enquiries">
                                                <div class="title"><i class="uk-icon-map-marker"></i> LOCATION</div>
                                                <div class="location">Veerappampalayam, Erode, Tamil Nadu 638011</div>
                                            </div>
                                        </div>
                                        {/* <div class="uk-width-medium-1-3 uk-panel">
                                            <div style={{ minHeight: "139px" }} class="contact-enquiries">
                                                <div class="title">MEDIA ENQUIRIES</div>
                                                <div class="phone"><i class="uk-icon-phone"></i>(748)-864-2151</div>
                                                <div class="mail">
                                                    <i class="uk-icon-envelope"></i>
                                                    <a href="malto:support@torbara.com">
                                                        support@torbara.com
                                                    </a>
                                                </div>
                                                <div class="location"><i class="uk-icon-map-marker"></i>217 Route 70, Lumberton, NC 28358</div>
                                            </div>
                                        </div> */}
                                        {/* <div class="uk-width-medium-1-3 uk-panel">
                                            <div style={{ minHeight: '139px' }} class="contact-enquiries">
                                                <div class="title">HEAD OFFICE</div>
                                                <div class="phone"><i class="uk-icon-phone"></i>(846)-356-9322</div>
                                                <div class="mail">
                                                    <i class="uk-icon-envelope"></i>
                                                    <a href="malto:support@torbara.com">
                                                        support@torbara.com
                                                    </a>
                                                </div>
                                                <div class="location"><i class="uk-icon-map-marker"></i>241 Adams Street, Huntington, NY 11743</div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3911.9493498338697!2d77.6809516!3d11.3384103!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96fb09fa36957%3A0x3c7297b45f701b3d!2sSPORTY%205!5e0!3m2!1sen!2sin!4v1633159604373!5m2!1sen!2sin" width="100%" height="450" className="mb-3" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div >
    )
}

export default Contact
