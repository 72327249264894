import React from 'react'

function Footer() {
    return (
        <div>
            <div className="bottom-wrapper">
                <div className="tm-bottom-f-box  ">
                    <div className="uk-container uk-container-center">
                        <section id="tm-bottom-f" className="tm-bottom-f uk-grid"
                            data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">

                            <div className="uk-width-1-1">
                                <div className="uk-panel">
                                    <div className="footer-logo">
                                        <a href="#"><img src="assests/images/sporty.svg"
                                      width="60px" alt="" /><span>Sporty</span>5</a>
                                    </div>
                                    <div className="footer-socials">
                                        <div className="social-top ml-5">
                                            <a   href="https://www.facebook.com/Sporty5-104099878678846" target="_blank"><span className="uk-icon-small uk-icon-hover uk-icon-facebook" style={{color:"white"}}></span></a>
                                            <a href="mailto:sporty5erode@gmail.com"><span className="uk-icon-small uk-icon-hover uk-icon-google" target="_blank" style={{color:"white"}}></span></a>
                                            <a href="https://www.instagram.com/_sporty5/"><span className="uk-icon-small uk-icon-hover uk-icon-instagram" target="_blank" style={{color:"white"}}></span></a>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <p className="footer-about-text d-flex justify-content-center mt-3" style={{color:"white"}}>
                                    A stadium is a place or venue for outdoor sports, concerts, or other events and consists of a field or stage either partly or completely surrounded by a tiered structure designed to allow spectators to stand or sit and view the event.                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <footer id="tm-footer" className="tm-footer">
                    <div className="uk-panel">
                        <div className="uk-container uk-container-center">
                            <div className="uk-grid">
                                <div className="uk-width-1-1">
                                    <div className="footer-wrap">
                                        <div className="foot-menu-wrap">
                                            <ul className="nav menu " >
                                                <li className="item-165"><a href="/PrivacyPolicy" className="d-flex justify-content-center" style={{color:"white"}} >Privacy Policy</a>
                                                </li>
                                                <li className="item-166"><a href="TermsandConditions" className="d-flex justify-content-center" style={{color:"white"}}>Terms and Conditions</a>
                                                </li>                                                
                                            </ul>  
                                        </div>
                                        <div className="copyrights mt-3"style={{color:"white"}} >Copyright © 2021 <a>Sporty5 Team</a>. All Rights Reserved.
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer
