import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateProfile() {
    const[profilelist,setProfilelist]=useState("")
    const[refresh,setRefresh]=useState(false)

useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}/player/profile`).then((res)=> { 
        console.log(res);
        setProfilelist(res.data.data)
        setUpdate((({name,
        email,
        phone,
        favsports,
        address,
        password})=>({name,
            email,
            phone,
            favsports,
            address,
            password})) (res.data.data))   
    }) 
}, [refresh])
     
const[update,setUpdate]=useState({
name:"",
email:"",
phone:"",
favsports:"",
address:"",
password:""
})
const handleupdate =(e)=>{
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/player/update`,update).then((res)=> { 
        setRefresh(!refresh)
        setTimeout(() => window.location.reload(),2000) 
        toast.success("Updated!")
    })
}  
const handlechange = (e) => {
    setUpdate({
        ...update,[e.target.name]:e.target.value
    })
}
    return (
        <div><ToastContainer />
               {/* breadcrumb */}
               <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
                <div className="uk-container uk-container-center">
                    <section id="tm-top-a" className="tm-top-a uk-grid uk-grid-collapse" data-uk-grid-match="{target:'> div > .uk-panel'}" data-uk-grid-margin="">
                        <div className="uk-width-1-1 uk-row-first">
                            <div className="uk-panel">
                                <div className="uk-cover-background uk-position-relative head-wrap" style={{ height: "290px", backgroundImage: "url('assests/images/head-bg.jpg')" }}>
                                    <img className="uk-invisible" src="assests/images/head-bg.jpg" alt="" height="290" width="1920" />
                                    <div className="uk-position-cover uk-flex uk-flex-center head-title">
                                        <h1> Profile</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
                {/* Title Bar */}
                <div class="uk-container uk-container-center alt">
                <ul style={{marginBottom:"0px"}} class="uk-breadcrumb">
                    <li><a href="/">Home</a>
                    </li>
                    <li class="uk-active"><span> Profile</span>
                    </li>
                </ul>
               
            </div>

          
            {/* login */}
      {/* <div style={{ backgroundImage: `url("https://st2.depositphotos.com/1005563/5211/i/950/depositphotos_52110913-stock-photo-sports-balls-with-equipment.jpg")`,opacity:"10"  }}> */}
    {/* <h3 style={{textAlign:"center",fontWeight:"bold"}}>User Profile  <button type="button" class="btn btn-dark" >  
                    <i className="uk-icon-edit"></i>
                </button></h3>  */}
                {/* <h4 style={{textAlign:"center"}}>Profile</h4> */}
                <div className="container" >
      <table class="table table-borderless d-flex justify-content-center my-3" >
          
  <thead >
    <tr >
      <th style={{fontWeight:"bold",textAlign:"right"}} scope="col">Name</th>
      <th style={{fontWeight:"bold",textAlign:"left"}} scope="col">:</th>
      <td style={{textTransform:"capitalize",textAlign:"left"}} >{profilelist.name}</td>
      </tr>
    <tr>
      <th style={{fontWeight:"bold",textAlign:"right"}} scope="col">Email </th>
      <th style={{fontWeight:"bold",textAlign:"left"}} scope="col">:</th>
      <td style={{textTransform:"capitalize"}} >{profilelist.email}</td>
      </tr>
    <tr>
      <th style={{fontWeight:"bold",textAlign:"right"}} scope="col">Phone </th>
      <th style={{fontWeight:"bold",textAlign:"left"}} scope="col">:</th>
      <td style={{textTransform:"capitalize"}} >{profilelist.phone}</td>
      </tr>
    <tr>
      <th style={{fontWeight:"bold",textAlign:"right"}} scope="col">FavSports</th>
      <th style={{fontWeight:"bold",textAlign:"left"}} scope="col">:</th>
      <td style={{textTransform:"capitalize"}} >{profilelist.favsports}</td>
      </tr>
    <tr>
      <th style={{fontWeight:"bold",textAlign:"right"}} scope="col">Address </th>
      <th style={{fontWeight:"bold",textAlign:"left"}} scope="col">:</th>
      <td style={{textTransform:"capitalize"}}>{profilelist.address}</td>
      </tr>
  </thead>
  
  
{/* <!-- Button trigger modal --> */}


{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" >
        <h5 class="modal-title" id="exampleModalLongTitle">Profile</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-dark">
      <div>
        <div className="container mb-3 mt-3">
            <div className="row w-100 d-flex  justify-content-center " >
            <div className="form-holder ml-5" style={{width:"500px"}}>
                <div className="form-content bg-dark" >
                    <div className="form-items">
                        <div className="page-links">
                            <a  href="/" className= "active" >Update Profile</a>
                        </div> 
                     <form >
                            <input className="form-control" type="text" style={{color:"black"}} value={update.name} onChange={ handlechange} name="name"  placeholder="Username" required />
                            <input className="form-control" type="text" name="email" style={{color:"black"}} value={update.email} onChange={ handlechange} placeholder="E-mail Address" required />
                            <input className="form-control" type="number" name="phone" style={{color:"black"}} value={update.phone} onChange={ handlechange} placeholder="Phone" required />
                            <input className="form-control" type="text" name="favsports" style={{color:"black"}} value={update.favsports} onChange={ handlechange} placeholder="Fav Sports" required />
                            <textarea className="form-control" type="text" name="address" style={{color:"black"}} value={update.address} onChange={ handlechange} placeholder="Address" required />
                            <div className="form-button">
                                <button id="submit"  onClick={handleupdate} className="ibtn">Update Profile</button> 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</table>
<div className="va-promo-text pb-4 d-flex justify-content-center"  style={{position:"relative"}} >
   <a className="btn btn-warning read-more my-0"  data-toggle="modal"  data-target="#exampleModalCenter" >Update Profile<i className="uk-icon-chevron-right"></i></a>
       </div>
</div> 
        </div>
    )
}

export default UpdateProfile
